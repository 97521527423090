<template>
	<div class="row mt-3">
		<label class="col-sm-4 col-form-label" for="id">{{ $t('monte.type_monte') }} *</label>
		<div class="col-sm-8">
			<e-select
				:disabled="disabled"
				v-model="type_monte"
				id="id"
				track-by="id"
				label="name"
				:placeholder="$t('monte.rechercher_type_monte')"
				:selectedLabel="$t('global.selected_label')"
				:options="type_montes"
				:allow-empty="false"
				:show-labels="false"
				:sortable="false"
				group-label="label"
				group-values="values"
				:group-select="false"
				:loading="loadingTypeMonte"
				:class="{ 'is-invalid': errors && errors.indexOf('type_monte') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import _sortBy from "lodash/sortBy"

	export default {
		name: 'TypeMonte',
		mixins: [Navigation, Tools, ContractMixin],
		props: {
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			avenant_typemonte:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
			type_montes_stallion: {
				type: Array,
				default: () => ( [] )
			},
			tiers_country:{
				type: Number,
				default: () => ( 0 )
			},
		},
		data () {
			return {
                loadingTypeMonte: false,
                type_montes: [
					{
						label: this.$t('monte.dispo_pays_tiers'),
						values: []
					},
					{
						label: this.$t('monte.autres'),
						values: []
					},
				],
			}
		},

		computed: {
			type_monte: {
				get() {
					return this.value
				},
				set(val) {
					this.loadingTypeMonte = false
					this.$emit('input', val)
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
			},

			async loadTypeMonte(force = false){
				this.loadingTypeMonte = true
				this.type_montes = [
					{
						label: this.$t('monte.dispo_pays_tiers'),
						values: []
					},
					{
						label: this.$t('monte.autres'),
						values: []
					},
				]
				let type_montes_ids = []
				this.type_monte = null

				if((this.contractconfig_id != 0 || force) && this.type_montes_stallion.length > 0) {
					let type_monte = this.type_montes_stallion//await this.getContractConfigTypeMonte(this.contractconfig_id, true)
					for (let i = 0; i < type_monte.length; i++) {
						// si ma condition est archivée et qu'elle est differente de celle du contrat, je ne l'affiche pas
						if(type_monte[i].contractconfigtypemonte_archive == 1 && ((this.avenant_typemonte && type_monte[i].contractconfigtypemonte_id !== this.avenant_typemonte) || !this.avenant_typemonte)) {
							continue;
						}

						const type = {
							id: type_monte[i].contractconfigtypemonte_id,
							contractconfig: type_monte[i].contractconfigtypemonte_contractconfig,
							type: type_monte[i].contract_type_monte.contracttypemonte_type,
							name: this.getTrad(type_monte[i].contract_type_monte.contracttypemonte_label) + " - " + type_monte[i].contractconfigtypemonte_label,
							config_conditions: type_monte[i].config_conditions,
							paillette: type_monte[i].contractconfigtypemonte_paillette
						}

						if(this.tiers_country && (type_monte[i].pays.length > 0 && type_monte[i].pays.includes(this.tiers_country) || type_monte[i].pays.length == 0)) {
							this.type_montes[0].values.push(type)
						}
						else {
							this.type_montes[1].values.push(type)
						}

						type_montes_ids.push(type_monte[i].contractconfigtypemonte_id)

						let selected = false

						if(this.avenant_typemonte == type_monte[i].contractconfigtypemonte_id) {
							selected = true
						}

						if(selected) {
							this.type_monte = {
								id: type_monte[i].contractconfigtypemonte_id,
								contractconfig: type_monte[i].contractconfigtypemonte_contractconfig,
								type: type_monte[i].contract_type_monte.contracttypemonte_type,
								name: this.getTrad(type_monte[i].contract_type_monte.contracttypemonte_label) + " - " + type_monte[i].contractconfigtypemonte_label,
								config_conditions: type_monte[i].config_conditions,
								paillette: type_monte[i].contractconfigtypemonte_paillette
							}
						}
                    }

                    // Lorsqu'un type de monte est en valide = 0 parce que le modèle a été supprimé, on le propose quand même en option
                    if(this.avenant_typemonte != null && this.avenant_typemonte) {
	                    const type_monte_old = await this.getContractConfigTypeMonteFromId(this.avenant_typemonte)
	                    if(type_monte_old.contractconfigtypemonte_contractconfig == this.contractconfig_id && type_monte_old != undefined && type_montes_ids.indexOf(type_monte_old.contractconfigtypemonte_id) == -1) {
		                    const option = {
		                        id: type_monte_old.contractconfigtypemonte_id,
		                        name: this.getTrad(type_monte_old.contract_type_monte.contracttypemonte_label) + " - " + type_monte_old.contractconfigtypemonte_label + "(" + type_monte_old.contract_config.season.season_label + ")",
								type: type_monte_old.contract_type_monte.contracttypemonte_type
		                    } 

		                    this.type_montes[0].values.push(option)
		                    this.type_monte = option
	                    }
                    }
				}

				this.type_montes = _sortBy(this.type_montes, ['name'])
				this.loadingTypeMonte = false
			}
		},
		watch: {
			contractconfig_id(){
				this.loadingTypeMonte = true
			},

			type_montes_stallion() {
				this.loadTypeMonte()
			}
		}
	}

</script>